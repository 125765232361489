/**
 * @class Wordpress
 */
import { ICredentials } from "./Interfaces/ICredentials";
import { IMedia } from "./Interfaces/IMedia";
import { IMenu } from "./Interfaces/IMenu";
import { IPage } from "./Interfaces/IPage";
import { IPost } from "./Interfaces/IPost";
import { IProduct } from "./Interfaces/IProduct";
import {ITokenFailResponse, ITokenSuccessResponse, ITokenValidationResponse} from "./Interfaces/ITokenResponses";
import {IUserUpdateRequest, IUserUpdateResponse} from "./Interfaces/IUser";
// tslint:disable-next-line:no-var-requires
const qs = require('qs');

interface IRequestParameters {
    path: string
    priority: number
}

const cache: { [s: string]: any; } = {};
let requests: IRequestParameters[] = [];

class Wordpress {
    public get posts() {
        const api = this;
        return {
            get: (params: { [s: string]: string; } = {},
                  signal: any = null,
                  returnResponse: boolean = false,
                  priority: number = 10
            ): Promise<IPost[]>|Promise<Response> => {
                return api.get('posts/?' + api.buildQuery(params), signal, '/wp/v2/', returnResponse, priority);
            }
        }
    }

    public get singlePost() {
        const api = this;
        return {
            get: (id: number|string, signal: any = null, priority = 10): Promise<IPost[]> => {
                return api.get('posts/' + id, signal, undefined, undefined, priority);
            }
        }
    }

    public get singlePostByQuery() {
        const api = this;
        return {
            get: (params: { [s: string]: string; } = {}, signal: any = null, priority: number = 10): Promise<IPost|null> => {
                return (api.posts.get(params, signal, false, priority) as Promise<IPost[]>).then((posts: IPost[]) => {
                    if (posts[0]) {
                        return posts[0];
                    }
                    return null;
                })
            }
        }
    }

    public get products() {
        const api = this;
        return {
            get: (params: { [s: string]: string; } = {},
                  signal: any = null,
                  returnResponse: boolean = false,
                  priority: number = 10
            ): Promise<IProduct[]>|Promise<Response> => {
                return api.get('products/?' + api.buildQuery(params), signal, '/squde/v1/', returnResponse, priority);
            }
        }
    }

    public get singleProduct() {
        const api = this;
        return {
            get: (id: number|string, signal: any = null, priority = 10): Promise<IProduct|null> => {
                return api.get('products/' + id, signal, '/squde/v1/', undefined, priority);
            }
        }
    }

    public get singleProductByQuery() {
        const api = this;
        return {
            get: (params: { [s: string]: string; } = {}, signal: any = null, priority: number = 10): Promise<IProduct|null> => {
                return (api.products.get(params, signal, false, priority) as Promise<IProduct[]>).then((products: IProduct[]) => {
                    if (products[0]) {
                        return products[0];
                    }
                    return null;
                })
            }
        }
    }

    public get pages() {
        const api = this;
        return {
            get: (params: { [s: string]: string; } = {}, signal: any = null, priority: number = 10): Promise<IPage[]> => {
                return api.get('pages/?' + api.buildQuery(params), signal, undefined, undefined, priority);
            }
        }
    }

    public get page() {
        const api = this;
        return {
            get: (params: { [s: string]: string; } = {}, signal: any = null, priority: number = 10): Promise<IPage|null> => {
                return api.pages.get(params, signal, priority).then((pages: IPage[]) => {
                    if (pages[0]) {
                        return pages[0];
                    }
                    return null;
                })
            }
        }
    }

    public get frontpage() {
        const api = this;
        return {
            get: (signal: any = null, priority: number = 10): Promise<IPage> => {
                return api.get('frontpage', signal, undefined, undefined, priority);
            }
        }
    }

    public get menus() {
        const api = this;
        return {
            get: (params: { [s: string]: string; } = {}, signal: any = null, priority: number = 10): Promise<IMenu[]> => {
                return api.get('menus/?' + api.buildQuery(params), signal, undefined, undefined, priority);
            }
        }
    }

    public get submissions() {
        const api = this;
        return {
            post: (params: { [s: string]: string; } = {}, signal: any = null, priority: number = 10): Promise<any> => {

                const select = document.querySelectorAll('script[src^="https://www.google.com/recaptcha/api.js"]');
                const postSubmissions = (data: { [s: string]: string; } = {}) => api.post('submissions/', data, signal, undefined, undefined, priority);

                if (select.length === 0) {
                    return postSubmissions(params)
                } else {
                    const siteKey = (select[0] as HTMLScriptElement).src.split('?render=')[1];

                    // @ts-ignore
                    return window.grecaptcha.execute(siteKey, {action: 'submit'}).then((token: string) => {
                        params['g-recaptcha-response'] = token;
                        return postSubmissions(params);
                    });
                }
            }
        }
    }


    public get media() {
        const api = this;
        return {
            get: (params: { [s: string]: string; } = {}, signal: any = null, priority: number = 10): Promise<IMedia[]> => {
                return api.get('media/?' + api.buildQuery(params), signal, undefined, undefined, priority);
            }
        }
    }

    public get image() {
        const api = this;
        return {
            get: (id: number, signal: any = null, priority: number = 10): Promise<IMedia|null> => {
                return api.get('media/' + id, signal, undefined, undefined, priority).then((image: IMedia) => {
                    return image;
                })
            }
        }
    }

    public get auth() {
        const api = this;
        return {
            login: (credentials: ICredentials, signal: any = null): Promise<any> => {
                return api.post('token/', credentials, signal, '/jwt-auth/v1/')
                    .then((result: ITokenSuccessResponse|ITokenFailResponse) => {
                        if('token' in result) {
                            const token = result.token;
                            window.localStorage.setItem('x-user-token', token);
                            window.localStorage.setItem('x-user-details', JSON.stringify(result));
                            return result;
                        }
                        throw result;
                    });
            },
            logout: (): Promise<void> => {
                window.localStorage.removeItem('x-user-token');
                window.localStorage.removeItem('x-user-details');
                return Promise.resolve();
            },
            validate: (signal: any = null): Promise<any> => {
                if (!window.localStorage.getItem('x-user-token') || !window.localStorage.getItem('x-user-details')) {
                    return Promise.reject(new Error('No token present'))
                }
                return api.post('token/validate', {}, signal, '/jwt-auth/v1/')
                    .then((data: ITokenValidationResponse) => {
                        const details = window.localStorage.getItem('x-user-details');
                        if (details !== null) {
                            data.details = JSON.parse(details);
                        }
                        return data;
                    });
            }
        };
    }

    get user() {
        const api = this;
        return {
            update: (params: IUserUpdateRequest = {}, signal: any = null): Promise<any> => {
                const userDataStr = window.localStorage.getItem('x-user-details');
                if (userDataStr) {
                    const userData = JSON.parse(userDataStr) as ITokenSuccessResponse;
                    return api.post('users/' + userData.ID, params, signal)
                        .then((user: IUserUpdateResponse) => {
                            userData.user_email = user.email;
                            userData.user_display_name = user.name;
                            window.localStorage.setItem('x-user-details', JSON.stringify(userData));

                            return user;
                        });
                }
                throw new Error('No userdata present');
            }
        }
    }
    private endpoint: string;
    constructor(endpoint: string) {
        this.endpoint = endpoint;
    }


    public get(path: string, signal: any = null, prefix?: string, returnResponse?: boolean, priority?: number) {
        return this.request(path, 'GET', {}, signal, prefix, returnResponse, priority);
    }

    public post(path: string, data: any, signal: any = null, prefix?: string, returnResponse?: boolean, priority?: number) {
        return this.request(path, 'POST', data, signal, prefix, returnResponse, priority);
    }

    public buildQuery(data: string|{ [s: string]: string|number|boolean; }) {
        return qs.stringify(data);
    }

    public fixData(data: any) {
        const isArray = !!data && !!data[0];
        if (!isArray) {
            data = [data];
        }
        if (!data.map) {
            return data;
        }
        data = data.map((row: any) => {
            if (row === null || typeof row !== 'object') {
                return row;
            }
            if (row.content && row.content.rendered) {
                row.content = row.content.rendered;
            }
            if (row.excerpt && row.excerpt.rendered) {
                row.excerpt = row.excerpt.rendered;
            }
            if (row.guid && row.guid.rendered) {
                row.guid = row.guid.rendered;
            }
            if (row.title && row.title.rendered) {
                row.title = row.title.rendered;
            }
            if (row.date) {
                row.date = new Date(row.date);
            }
            if (row.date_gmt) {
                row.date_gmt = new Date(row.date_gmt);
            }
            if (row.modified) {
                row.modified = new Date(row.modified);
            }
            if (row.modified_gmt) {
                row.modified_gmt = new Date(row.modified_gmt);
            }
            if (row.permalink && !row.link) {
                row.link = row.permalink;
            }
            if (!row.url && row.link) {
                row.url = row.link;
            }
            if (row.url && row.url.split('://').length > 1 && (row.url.split(window.location.host).length > 1 || row.url.split('.local').length > 1)) {
                row.url = row.url.split('://')[1];
                row.url = row.url.split('/');
                delete row.url[0];
                row.url = row.url.join('/', row.url);
            }
            if (row.subMenu) {
                row.subMenu = this.fixData(row.subMenu);
            }
            return row;
        });
        if (!isArray) {
            return data[0];
        }
        return data;
    }

    private request(path: string,
                    method: string,
                    postData: any = {},
                    signal: any = null,
                    prefix?: string,
                    returnResponse?: boolean,
                    priority: number = 10) {
        const thisObject = this;

        if (cache[path] && method === 'GET') {
            if (typeof cache[path].then === 'function') {
                return cache[path];
            }
            if (!returnResponse) {
                return Promise.resolve(cache[path]);
            }
        }

        cache[path] = new Promise((resolve: any) => {

            requests.push({path, priority});

            setTimeout(() => {

                const firstHigherPriorityRequest = requests
                    .filter((request: IRequestParameters) => request.path !== path)
                    .filter((request: IRequestParameters) => request.priority < priority)
                    .sort((requestA: IRequestParameters, requestB: IRequestParameters) => {
                        return requestA.priority > requestB.priority ? -1 : 1;
                    })
                    .map((request: IRequestParameters) => cache[request.path])
                    .filter((cachePath: any) => cachePath && typeof cachePath.then === 'function')
                    [0];


                // Priority logging
                // // tslint:disable-next-line:no-console
                // console.log('begin: ' + path);
                // // tslint:disable-next-line:no-console
                // console.log(requests
                //     .filter((request: IRequestParameters) => request.path !== path)
                //     .filter((request: IRequestParameters) => request.priority < priority)
                //     .sort((requestA: IRequestParameters, requestB: IRequestParameters) => {
                //         return requestA.priority > requestB.priority ? -1 : 1;
                //     })
                //     .filter((request: IRequestParameters) => cache[request.path] && typeof cache[request.path].then === 'function')
                //     .map((request: IRequestParameters) => request.path));
                // // tslint:disable-next-line:no-console
                // console.log('end: ' + path);



                const thisRequest = (() => {
                    const args: any = {
                        method
                    };
                    if (signal) {
                        args.signal = signal;
                    }

                    args.headers = {};
                    if (method === 'POST') {
                        args.body = this.buildQuery(postData);
                        args.headers = {
                            'Content-Type':'application/x-www-form-urlencoded'
                        };

                    }

                    const token = window.localStorage.getItem('x-user-token');
                    if (!!token && token.length > 0) {
                        args.headers.Authorization = 'Bearer ' + token;
                    }

                    let endpoint = this.endpoint;
                    if (prefix) {
                        endpoint = endpoint.split('/wp/v2/').join(prefix);
                    }

                    cache[path] = fetch(endpoint + path, args)
                        .then((response) => {
                            requests = requests.filter((request: IRequestParameters) => request.path !== path);
                            if (response.status === 200) {
                                if (returnResponse) {
                                    cache[path] = undefined;
                                    return response;
                                }
                                return response.json().then((data: any) => {
                                    cache[path] = thisObject.fixData(data);

                                    return cache[path];
                                });
                            } else {
                                throw response;
                            }
                        })
                        .catch((error: any) => {
                            requests = requests.filter((request: IRequestParameters) => request.path !== path);
                            if (error.status === 403 && token !== null) {
                                return this.auth.logout().then(() => {
                                    window.location.reload();
                                })
                            }
                            throw error;
                        });

                    return cache[path];
                });


                if (firstHigherPriorityRequest) {
                    (firstHigherPriorityRequest as Promise<any>).then(() => {
                        resolve(thisRequest());
                    });
                } else {
                    resolve(thisRequest());
                }
            });

        });
        return cache[path];

    }
}

export default Wordpress;
